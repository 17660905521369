export const products = [
  {
    id: 0,
    title: "Защита ребенка",
    description: [
      "С услугой «Защита ребенка» вы минимизируете риски от потери своих детей!",
      "Браслеты имеют уникальный ID номер, информацию для нашедшего и телефон круглосуточной службы поддержки."
    ],
    features: [
      "силиколовый браслет (1 шт.)",
      "сервис возврата найденных ключей владельцу (на 12 месяцев)",
      "подарок для человека, нашедшего и вернувшего утерянные ключи"
    ],
    imageUrl: 'bracelet.png',
    link: 'https://www.ozon.ru/product/kliktuaydi-braslet-gibkiy-1152786164/?_bctx=CAQQ4fpJ&asb=Sg55LzP6TXR%252Bx%252FI1RyA2i4hl6FCQ2K0Oryu4NHYkdDc%253D&asb2=xRlOjlwvzAN9eO0_3-fl3nsIu2U1zEXG_pjYfXgcOGUZ1btIA8ewaCfrMeN1oOrhogEUjic0YVqEGJaeqKv5MA&avtc=1&avte=4&avts=1732402728&hs=1'
  },
    {
      id: 1,
      title: "Ключи на месте",
      description: [
        "С услугой «Ключи на месте» Вы минимизируете риски от потери своих ключей!",
        "Нашедший Ваши ключи позвонит в CLICKTOID, а мы заберем находку и вернем ее Вам.",
        "Этот сервис поможет Вам вернуть забытые ключи и сэкономить деньги на замене замка."
      ],
      features: [
        "специальный брелок для механических/электронных ключей (1 шт.)",
        "сервис возврата найденных ключей владельцу (на 12 месяцев)",
        "подарок для человека, нашедшего и вернувшего утерянные ключи"
      ],
      imageUrl: 'keys.png',
      link: 'https://www.ozon.ru/product/brelok-dlya-klyuchey-s-zashchitoy-ot-poteri-i-kruglosutochnoy-podderzhkoy-1151900875/?_bctx=CAQQ4fpJ&asb=DMl33RmPtGXMn9h14VBFJMj83rGfYCrBvduWfZdc2yw%253D&asb2=W6Krg395l0rvsUrMZ0Ee_dizLsLmEd-MR6vYWa7LY2aYnwVtU4j7Uv1BufOiXxQOq1R2dBviFiz9tMWbmrALVg&avtc=1&avte=2&avts=1728143462&hs=1'
    },
    {
      id: 2,
      title: "Любимый друг",
      description: [
        "Услуга «Любимый друг» предоставит Вам возможность оперативно получить информацию о метонахождении Вашего питомца, если он потерялся!",
        "CLICKTOID предлагает Вам кулон с уникальным ID номером, информацией для Нашедшего и телефоном круглосуточной Службы поддержки. Кулон крепится на ошейник Вашего питомца.",
        "К сожалению, любой домашний питомец может потеряться в стрессовой ситуации. Например, испугаться громкого звука салюта или убежать, погнавшись за другими животными.",
        "Конечно, Вы можете изготовить такой кулон самостоятельно, но тогда нужно быть готовым к тому, что Ваши персональные данные попадут в открытый доступ, и появится риск стать жертвой мошенников.",
        "С сервисом CLICKTOID Ваши данные останутся под защитой, а информацию о местонахождении любимого друга Вы получите в кратчайшие сроки!",
      ],
      features: [
        "специальный кулон для животного (1 шт.)",
        "сервис немедленного оповещения о местонахождении животного (на 12 месяцев)"
      ],
      imageUrl: 'birka.png',
      link: 'https://www.ozon.ru/product/adresnik-dlya-zhivotnogo-s-unikalnym-id-i-zashchitoy-ot-poteri-1168153202/?_bctx=CAQQ4fpJ&asb=MFoyTwEwmLTG8plGA%252Fp1x0QECkhtbODjkSQLGp%252BtLzc%253D&asb2=N49XOO8KwR0fcjPfqQgOn1ze3n5HlgXuTTWCRlocH69Ej9mkpoRD9dZKFrTQBjxY4H9cfj2I4crW-Ix1XHGiAg&avtc=1&avte=2&avts=1728143462&hs=1'
    },
    {
        id: 3,
        title: "Защита документов",
        description: [
          "Услуга «Защита документов» - это то, что необходимо каждому. Если Вы потеряете свой гражданский паспорт или водительское удостоверение, проблем не миновать.",
          "Потеря загранпаспорта во время путешествия доставит еще больше переживаний, которые омрачат Ваш отпуск!",
          "Наклейка с уникальным ID номером поможет свести риск потери к минимуму!",
          "Нашедший Ваши документы позвонит в CLICKTOID, а мы заберем находку и вернем ее Вам в кратчайшие сроки из любой точки мира.",
          "Уже через короткое время Вы вернете свой документ, а про потерю будете вспоминать, как о приключении со счастливым концом!",
          "Мы предлагаем мыслить шире, нашу наклейку Вы можете прикрепить не только на документ, но и портативное цифровое устройство: ноутбук, фотоаппарат, сотовый телефон, планшет.",
          "Сервис CLICKTOID будет одинаково хорошо работать для Вас!"
        ],
        features: [
          "сет из 2-х наклеек на документ с фотографией или портативное цифровое устройство (1 шт.)",
          "сервис возврата найденного документа и портативного цифрового устройства (на 12 месяцев)",
          "подарок для человека, нашедшего и вернувшего утерянный документ или цифровое устройство"
        ],
        imageUrl: 'passport.png',
        link: 'https://www.ozon.ru/product/nakleyka-stiker-na-telefon-noutbuk-pasport-s-zashchitoy-ot-poteri-i-kruglosutochnoy-podderzhkoy-1144872214/?_bctx=CAQQ4fpJ&asb=cU5pDqBT%252Bhp1nSgxVevN4QxBnyjkj3S9TNQpS%252FKE6hg%253D&asb2=9e_MTcTRCESvVrDkQ_izIOfGmZURwNbWtG5D_VXuVImMgsLuZbvxTfr7pxB4xI_xAi5EfRDWcGx9b_JUbNGYGg&avtc=1&avte=2&avts=1728143462&hs=1'
      },
      {
        id: 4,
        title: "Забота о близких",
        description: [
          "Набор меток для близких с сервисом на 1 год «Забота о близких». С услугой «Забота о близких» вы минимизируете риски от потери своих родственников!",
          "Визитка, кулон и браслет имеют уникальный ID номер, информацию для нашедшего и телефон круглосуточной службы поддержки.",
          "В цену включено вознаграждение за возврат и защита от мошенников.",
          "CLICKTOID эффективно помогает вернуть владельцу потерянные вещи и людей из любой точки мира."
        ],
        features: [
          "Визитка с уникальным ID номером",
          "Кулон с уникальным ID номером",
          "Браслет с уникальным ID номером"
        ],
        imageUrl: 'paket.png',
        link: 'https://www.ozon.ru/product/nabor-dlya-puteshestviy-s-zashchitoy-ot-poteri-blizkih-1152798264/?_bctx=CAQQ4fpJ&asb=voFD63gC%252Br7n0W0spoOJXdE7QyHZC4WBxfNglu2NXmg%253D&asb2=tNENSbU4OJdHBKdK8XIRh9ShVPKMS-QgYnysz7Grvcjtds622dEbbSBRQFgMYK2IbzbPrzDkgId8wqsqzXdvmQ&avtc=1&avte=2&avts=1728143462&hs=1'
      },
      {
        id: 5,
        title: "Защита вещей",
        description: [
          "Набор меток для вещей с сервисом на 1 год «Защита вещей». С услугой «Защита вещей» вы минимизируете риски от потери своих вещей!",
          "Бирка на багаж, брелок на ключи и наклейки на девайсы и документы имеют уникальный ID номер, информацию для нашедшего и телефон круглосуточной службы поддержки.",
          "В цену включено вознаграждение за возврат и защита от мошенников.",
          "CLICKTOID эффективно помогает вернуть владельцу потерянные вещи из любой точки мира."
        ],
        features: [
          "Багажная бирка",
          "Брелок для ключей",
          "Наклейки на документы и устройства"
        ],
        imageUrl: 'paket-2.png',
        link: 'https://www.ozon.ru/product/nabor-dlya-puteshestviy-s-zashchitoy-ot-poteri-veshchey-i-kruglosutochnoy-podderzhkoy-po-vsemu-miru-1152760388/?_bctx=CAQQ4fpJ&asb=PiMzS9Oebr%252FdV3UPucuJ3Otkezv0UX7Y5w4bX%252BFQEYM%253D&asb2=vFWNeQw0f0iAoyxj358n8QpesOm5Ra3giQOZ0W2XcXEDpM05vLgzPigsQ7_axYlQ9wNKcHIZt5GI0JQPPSFX7g&avtc=1&avte=2&avts=1728143462&hs=1'
      },
      {
        id: 6,
        title: "Максимальная защита",
        description: [
          "Набор меток для вещей и близких с сервисом на 1 год «Максимальная защита». С услугой «Максимальная защита» вы минимизируете риски от потери своих вещей и близких!",
          "Браслет, карта, кулон, бирка, брелок и наклейка имеют уникальный ID номер, информацию для нашедшего и телефон круглосуточной службы поддержки.",
          "Информация дублирована на английском.",
          "В цену включено вознаграждение за возврат и защита от мошенников."
        ],
        features: [
          "Браслет для ребёнка",
          "Карта для пенсионера",
          "Кулон для питомца",
          "Бирка на багаж",
          "Брелок для ключей",
          "Наклейки на документы и устройства"
        ],
        imageUrl: 'paket-3.png',
        link: 'https://www.ozon.ru/product/nabor-dlya-puteshestviy-s-zashchitoy-ot-poteri-veshchey-i-blizkih-1152803311/?_bctx=CAQQ4fpJ&asb=VUeva67by8MutFWdxvcdzoyTrtFROX7E0ZORmH3OIqQ%253D&asb2=zuvOcuKnFfWTkZlG2kCKeQ_CAvWnYYcOo9e2Y6PaHpisnKuuo_kCLF2Xjzd4FyeC&avtc=1&avte=2&avts=1728143462&hs=1'
      }
  ];